let Environment = {
  nftPurchase: "0x1119c2d708E42Fb591FCe77283b0791a135855ba",
  chinaTomi: "0xa84D39FEc03802AbCACb110116712c373d5ddbc4",
  busd: "0x6fEA2f1b82aFC40030520a6C49B0d3b652A65915",
  tomi: "0x84f0bc91f23649c76139d402a1c00c6ac19a4400",
  instituson: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0x25762231808F040410586504fDF08Df259A2163c",
  wbtc: "0x54410d42628C7fD6720C489219033345Bcb7Cc73",
  link: "0x37bBd68526C9BDb49f18fB36983701E344f2A163",
  pepe: "0x0592EBa69Aa5f2eF68d9cb740fBaB2c0c0CC6A91",
  unisape: "0x079c46A5Ba477400a326E03Ad8D22FaBeaE57b4F",
  gems: "0xB297D4b01b286de35Ee12cdCe89Ec9488EEec0C2", //testnet
  GemsStaking: "0x034f504F34330eA87dcc8aBae2935eac9fD2eFaE", //TESTNET
  dop:'0xa048E46C35cf210bB0d5bb46b2DD06828Ef17893',
};
export default Environment;